header {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 4rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  z-index: 9999;
}

.header {
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
  align-items: center;
  height: 4rem;
}

.logo-link {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  grid-column-start: 3;
}

@media (max-width: 993px) {

  .logo-link {
    grid-column: span 4 / span 4;
  }

  .logo-link {
    grid-column-start: 3;
  }
}

@media (max-width: 768px) {

  .logo-link {
    grid-column: span 5 / span 5;
  }

  .logo-link {
    grid-column-start: 3;
  }
}

@media (max-width: 480px) {

  .logo-link {
    grid-column: span 7 / span 7;
  }

  .logo-link {
    grid-column-start: 2;
  }
}

.logo-link {
  max-height: 4rem;
}

.header-nav-container {
  grid-column-start: 13;
  grid-column-end: 23;
  height: 4rem;
}

.header-button-container {
  grid-column: span 7 / span 7;
  grid-column-start: 19;
  height: 100%;
  justify-self: end;
  display: none;
}

@media (max-width: 993px) {

  .header-button-container {
    display: block;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  height: 4rem;
  gap: 2rem;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(217 233 242 / var(--tw-border-opacity));
  align-items: center;
}

.nav-item {
  display: inline-block;
  cursor: pointer;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.nav-item + .header-nav-item {
  margin-left: 2rem;
}
.nav-item:hover {
  --tw-text-opacity: 1;
  color: rgb(0 110 168 / var(--tw-text-opacity));
}

footer {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}

.footer {
  z-index: 10;
  margin-left: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
  max-width: calc(1600px + ((100vw - 1600px)/ 2 ));
}

.footer-image-cell {
  grid-column: span 5 / span 5;
  grid-column-start: 3;
  grid-row-start: 1;
  padding-top: 4rem;
}
.footer-image-cell .footer-logo-image {
  margin-bottom: 2rem;
  height: auto;
  width: 6rem;
}

.footer-text-cell {
  grid-column: span 3 / span 3;
  grid-column-start: 3;
  grid-row-start: 2;
}

.footer-software-cell {
  grid-column: span 3 / span 3;
  grid-column-start: 7;
  grid-row-start: 2;
}

.footer-opening-cell {
  grid-column: span 3 / span 3;
  grid-column-start: 11;
  grid-row-start: 2;
}

.footer-contact-cell {
  grid-column: span 3 / span 3;
  grid-column-start: 15;
  grid-row-start: 2;
}

.footer-corner {
  grid-column-start: 19;
  grid-column-end: 25;
  grid-row-start: 1;
  grid-row-end: 4;
  --tw-bg-opacity: 1;
  background-color: rgb(217 233 242 / var(--tw-bg-opacity));
  padding: 5rem;
}

.sub-footer {
  grid-column: span 15 / span 15;
  grid-column-start: 3;
  grid-row-start: 3;
  justify-self: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 3rem;
  width: 100%;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(217 233 242 / var(--tw-border-opacity));
}

.sub-footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2rem;
}

.sub-footer-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-transform: uppercase;
}

.sub-footer-copyright {
  grid-column: span 19 / span 19;
  grid-column-start: 4;
  grid-row-start: 3;
  justify-self: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

@media (max-width: 993px) {

  .sub-footer-copyright {
    grid-row-start: 4;
  }
}

@media (max-width: 768px) {

  .sub-footer-copyright {
    grid-row-start: 5;
  }
}

.app-main {
  padding-top: 4rem;
}

.block-container {
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.block-intro {
  position: relative;
}
.block-intro .intro-block-container {
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 50;
  transform: translate(0, -50%);
}
.block-intro .intro-block-container .block-container {
  position: relative;
}
.block-intro .intro-block-container .block-container .content {
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-intro .background {
  grid-column-start: 1;
  grid-column-end: 25;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 90vh;
}
.block-intro .sliding-background .bild {
  position: absolute;
  top: 0px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
}
.block-intro .sliding-background .rendered {
  width: 100%;
  height: 90vh;
}

.block-grid4IconText {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-bg-opacity: 1;
  background-color: rgb(217 233 242 / var(--tw-bg-opacity));
  padding: 4rem;
}
.block-grid4IconText .link {
  position: absolute;
  right: 4rem;
  top: 4rem;
}
.block-grid4IconText .item-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
}
.block-grid4IconText .item-container .item .icon {
  height: 3rem;
  width: 3rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.block-grid3ImageTextLink {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-grid3ImageTextLink .item-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}
.block-grid3ImageTextLink .item-container .item .bild {
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-grid3Product {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-grid3Product .item-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}
.block-grid3Product .item-container .item .bild {
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-grid3Product .item-container .item .merkmale {
  list-style-type: disc;
  padding-left: 1.25rem;
}
.block-customerTestimonials {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
  padding: 4rem;
}
.block-customerTestimonials .logo-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.block-customerTestimonials .logo-container .logo {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 56.25%;
}
.block-customerTestimonials .testimonial-slider .testimonial {
  display: flex;
}
.block-customerTestimonials .testimonial-slider .testimonial .portrait {
  height: 8rem;
  width: 8rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9999px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
}
.block-filter {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  text-align: center;
}
.block-filter .filter-buttons .filter-button {
  display: inline-block;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(46 59 78 / var(--tw-border-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.block-grid4Product {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-grid4Product .item-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
}
.block-grid4Product .item-container .item .bild {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 100%;
}
.block-kontakt {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-bg-opacity: 1;
  background-color: rgb(217 233 242 / var(--tw-bg-opacity));
  padding: 4rem;
  display: flex;
}
.block-kontakt .portrait {
  height: 8rem;
  width: 8rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9999px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
}
.block-kontakt .content .link {
  position: absolute;
  right: 4rem;
  top: 4rem;
}

.block-aufzaehlung {
  position: relative;
  grid-column-start: 4;
  grid-column-end: 22;
}
.block-aufzaehlung .item-container .item {
  display: flex;
}
.block-aufzaehlung .item-container .item .icon {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
  border-radius: 9999px;
}
.block-aufzaehlung .item-container .item .bild {
  height: 8rem;
  width: 8rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9999px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
}
.block-zeitstrahl {
  position: relative;
  grid-column-start: 4;
  grid-column-end: 22;
}
.block-zeitstrahl .item-container .item {
  display: flex;
}
.block-zeitstrahl .item-container .item .icon {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
  border-radius: 9999px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.block-text {
  position: relative;
  grid-column-start: 5;
  grid-column-end: 21;
}
.block-text a {
  --tw-text-opacity: 1;
  color: rgb(0 110 168 / var(--tw-text-opacity));
}
.block-text a:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.block-text p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.block-text i, .block-text em {
  font-weight: 500;
}
.block-text blockquote {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  --tw-border-opacity: 1;
  border-color: rgb(0 110 168 / var(--tw-border-opacity));
  padding-left: 0.75rem;
  border-left-width: 3px;
  font-style: italic;
}
.block-text ul, .block-text ol {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 2.25rem;
}
.block-text ul > li + li, .block-text ol > li + li {
  margin-top: 0.25rem;
}
.block-text ul > li > ol, .block-text ul > li > ul, .block-text ol > li > ol, .block-text ol > li > ul {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.block-text ul {
  list-style-type: disc;
}
.block-text ol {
  list-style-type: decimal;
}

.block-teamEinzelperson-bild {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 11;
}
.block-teamEinzelperson-bild .portrait {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.block-teamEinzelperson-content {
  position: relative;
  grid-column-start: 12;
  grid-column-end: 23;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.block-akkordeon {
  position: relative;
  grid-column-start: 4;
  grid-column-end: 22;
}
.block-akkordeon .item-container .item {
  margin-bottom: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}
.block-akkordeon .item-container .item .titel {
  padding: 2rem;
}
.block-akkordeon .item-container .item .text {
  display: none;
  padding: 2rem;
}
.block-akkordeon .item-container .item .text.akkordeon-active {
  display: block;
}
.block-akkordeon .item-container .item .akkordeon-icon {
  position: absolute;
  right: 2rem;
}

.block-bildRegler {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-bildRegler .bild {
  position: absolute;
  top: 0px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
}
.block-bildRegler .rendered {
  width: 100%;
  height: 500px;
}

.block-chat {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
  --tw-bg-opacity: 1;
  background-color: rgb(217 233 242 / var(--tw-bg-opacity));
  padding: 4rem;
  display: flex;
}
.block-chat .portrait {
  height: 8rem;
  width: 8rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9999px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 8rem;
}
.block-chat .content .link {
  position: absolute;
  right: 4rem;
  top: 4rem;
}

.block-gallery-bild {
  position: relative;
  grid-column-start: 4;
  grid-column-end: 10;
}
.block-gallery-bild .bild {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-gallery-bild .gallery {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-gallery-bild .gallery .bild {
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-gallery-bild .gallery .bild .filter-layer {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(46, 59, 78, 0.5);
}
.block-gallery-bild .gallery .bild .open-button {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  transform: translate(-50%, -50%);
}

.block-gallery-content {
  position: relative;
  grid-column-start: 11;
  grid-column-end: 22;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.lightbox {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
}
.lightbox .close-button {
  position: fixed;
  top: 3rem;
  right: 3rem;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.lightbox .prev-button {
  position: fixed;
  left: 3rem;
  top: 50%;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  transform: translate(0, -50%);
}
.lightbox .next-button {
  position: fixed;
  right: 3rem;
  top: 50%;
  transform: translate(0, -50%);
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.lightbox .lightbox-container {
  position: fixed;
  right: 8rem;
  left: 8rem;
  top: 50%;
  height: 75%;
  max-height: 100%;
  max-width: 100%;
  transform: translate(0, -50%);
}
.lightbox .lightbox-container .lightbox-bild {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.lightbox .lightbox-container .lightbox-text {
  text-align: center;
}

.block-grid3Team {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 23;
}
.block-grid3Team .item-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}
.block-grid3Team .item-container .item .bild {
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.overlay {
  position: fixed;
  overflow: hidden;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s;
  pointer-events: none;
}
.overlay.overlay-show {
  pointer-events: all;
  overflow-y: scroll;
  z-index: 10000;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s;
}
.overlay .overlay-inner {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.overlay.overlay-navigation-desktop {
  top: 0px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(24, 1fr);
  overflow: visible;
  margin-top: 69px;
  grid-template-rows: max-content max-content;
  height: max-content;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s, z-index 0s linear 0.2s;
}
.overlay.overlay-navigation-desktop.overlay-show {
  z-index: 10000;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s, z-index 0s linear 0s;
}
.overlay.overlay-navigation-desktop > .overlay-inner {
  max-width: none;
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  position: relative;
  grid-column: span 22 / span 22;
  grid-column-start: 3;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-top: 1px solid #f3f3f3;
}
.overlay.overlay-navigation-desktop .nav-overlay-outer-grid {
  display: grid;
  grid-template-columns: repeat(22, 1fr);
}
.overlay.overlay-navigation-desktop .nav-overlay-cell {
  grid-column: span 16 / span 16;
  grid-column-start: 4;
}
@media (max-width: 1600px) {

  .overlay.overlay-navigation-desktop .nav-overlay-cell {
    grid-column: span 17 / span 17;
  }

  .overlay.overlay-navigation-desktop .nav-overlay-cell {
    grid-column-start: 4;
  }
}
.overlay.overlay-navigation-desktop .nav-overlay-innner-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 4rem;
  row-gap: 1.25rem;
}
.overlay.overlay-navigation-desktop .nav-overlay-group-title {
  display: flex;
  height: 2rem;
  -webkit-user-select: none;
          user-select: none;
  --tw-border-opacity: 1;
  border-color: rgb(1 156 218 / var(--tw-border-opacity));
  padding-bottom: 0.25rem;
  border-bottom-width: 1px;
}
.overlay.overlay-navigation-desktop .nav-overlay-group a {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.overlay.overlay-navigation-desktop .nav-overlay-group a:hover {
  --tw-text-opacity: 1;
  color: rgb(0 110 168 / var(--tw-text-opacity));
}
.overlay.overlay-navigation-desktop .nav-overlay-group a:hover > div {
  text-decoration: underline;
}